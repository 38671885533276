@use "sass:math";
// bg-$color | text-$color helper
@include syntax-colors($white : #fff,
  $neutral : #FCFCFC,
  $grey-dark :#4D4D4D,
  $grey :#707070,
  $grey-alt :#94969B,
  $grey-light :#E4E6EB,
  $blue-dark :#292E37,
  $blue : #374052,
  $blue-light : #078CC7,
  $blue-extra-light : #90AAED,
  $green : #00CAA8,
  $orange : #FF9E68,
  $orange-alt : #F5C17D,
  $red : #DE377C,
  $red-alt : #FF3131,
  $purple : #9E3678,
  $purple-light : #C029C0,
  $green-uc : #32B084, );

// OPACITY HELPER
@for $i from 0 to 101 {
  .font-#{$i} {

    font-size: $i * 1px;
  }
}

@for $i from 0 to 101 {
  .borda-#{$i} {
    border-radius: $i * 1px !important;
  }
}

@for $i from 0 to 11 {
  .opacity-#{$i} {

    // opacity: calc(#{$i} / 10);
    opacity: math.div($i, 10);
  }
}

@for $i from 0 to 101 {
  .w-#{$i} {
    width: $i * 1%;
  }

  .h-#{$i} {
    height: $i * 1%;
  }
}

* {
  @extend .text-blue;
}

.img-fluid {
  max-width: 100%;
  height   : auto;
}

.font-header {
  // font-family: $font-secondary_b;
  // font-weight: bold;
  // font-size  : 26px;
  font: normal normal bold 26px/35px $font-secondary_eb;
}

.font-btn {
  @extend .font-header;
  // font          : normal normal bold 26px/35px $font-secondary_b;
  letter-spacing: 1px;
}

.font-regular {
  font-family: $font-secondary;
  font-weight: 400;
  font-size  : 14px;
}

.link {
  text-decoration: none;
}

.input {

  &[type="text"],
  &[type="email"],
  &[type="number"],
  &[type="password"] {
    height        : 45px;
    background    : #FFFFFF 0% 0% no-repeat padding-box;
    border        : 1px solid #C5C5C5;
    border-radius : 5px;
    padding       : 13px 18px;
    font          : normal normal normal 14px/19px $font-secondary;
    letter-spacing: .75px;
  }

  &[type="checkbox"] {
    background-color: #bada55 !important;
    margin          : 0;
    background      : #FFFFFF 0% 0% no-repeat padding-box;
    border          : 1px solid #C5C5C5;
    border-radius   : 5px;
  }
}

.video-container {
  position      : relative;
  padding-bottom: 56.25%; //?? 16:9
  height        : 0;

  // width       : 90%;
  display        : flex;
  align-items    : center;
  justify-content: center;

  .video-js {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
  }

  .vjs-big-play-button {
    position : absolute;
    top      : 50%;
    left     : 50%;
    transform: translate(-50%, -50%);
  }
}

.page {
  padding         : 64px;
  max-width       : 1600px;
  width           : 100%;
  margin          : 0 auto;
  // padding-right: 0;
  padding-top     : 75px !important;

  // padding-left: 128px;
  //padding-left: 133px;
  &--full {
    @extend .page;
    padding  : 0;
    max-width: unset;
  }
}

@media (max-width: 1024px) {
  .page {
    // padding: 30px !important;
    padding-right: 30px !important;
    padding-left : 30px !important;
  }
}

@media (max-width: 640px) {
  .page {
    padding-right: 15px !important;
    padding-left : 15px !important;
    // padding: 10px !important;
  }
}




//TOASTS
.toast-container * {
  box-sizing: border-box;
  color     : white;
}

.toast-container .ngx-toastr {
  padding      : 20px 30px 20px 60px !important;
  width        : 380px !important;
  border-radius: 4px !important;
}

.toast-success {
  background-color: #374052 !important;

  .toast-title {
    color      : #90AAED !important;
    font-family: $font-primary_eb !important;
    font-size  : 18px !important;
  }

  .toast-message {
    color      : #fff !important;
    font-family: $font-primary !important;
  }
}


//MODALS
.modal-header-center {
  justify-content: center !important;
  padding-bottom : 0 !important;
  border-bottom  : none !important;
}

.modal-text-center {
  justify-content: center !important;
  padding-bottom : 0 !important;
  border-bottom  : none !important;
  font-size      : 18px !important;
  text-align     : center;
  margin         : 0 !important;
}

.modal-footer-center {
  border-top     : none !important;
  padding        : 0 !important;
  padding-bottom : 20px !important;
  justify-content: center !important;

  .bg-red {
    background: #DE377C !important;
    color     : white !important;
  }
}

.modal-lg,
.modal-xl {
  max-width: 600px !important;
}

// Css Utils

.cursor-pointer {
  cursor: pointer;
}

// Font Weight
.font-thin {
  font-weight: 100
}

.font-extralight {
  font-weight: 200
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.font-extrabold {
  font-weight: 800
}

.font-black {
  font-weight: 900
}

// Max With
.max-w-0 {
  max-width: 0rem;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-min {
  max-width: min-content;
}

.max-w-max {
  max-width: max-content;
}

.max-w-fit {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .header {
    margin-top   : 20px;
    margin-bottom: 20px !important;
    padding-left : 10px !important;

    .font-30 {
      font-size: 18px !important;
    }
  }
}