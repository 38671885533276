@font-face {
  font-family: Nunito;
  src        : url("../../assets/fonts/Nunito/Nunito-VariableFont_wght.ttf");
}

@font-face {
  font-family: Nunito-extra-bold;
  src        : url("../../assets/fonts/Nunito/static/Nunito-ExtraBold.ttf");
}

@font-face {
  font-family: Nunito-bold;
  src        : url("../../assets/fonts/Nunito/static/Nunito-Bold.ttf");
}

@font-face {
  font-family: creato-display_regular;
  src        : url("../../assets/fonts/creato_display/CreatoDisplay-Regular.otf") format("opentype");
}

@font-face {
  font-family: creato-display_bold;
  src        : url("../../assets/fonts/creato_display/CreatoDisplay-Bold.otf") format("opentype");
}

@font-face {
  font-family: creato-display_extra_bold;
  src        : url("../../assets/fonts/creato_display/CreatoDisplay-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: source_code_pro_bold;
  src        : url("../../assets/fonts/SourceCodePro/SourceCodePro-Bold.ttf") format("opentype");
}

@font-face {
  font-family: source_code_pro_semi_bold;
  src        : url("../../assets/fonts/SourceCodePro/SourceCodePro-Semibold.ttf") format("opentype");
}

@font-face {
  font-family: source_code_pro_reg;
  src        : url("../../assets/fonts/SourceCodePro/SourceCodePro-Regular.ttf") format("opentype");
}

@font-face {
  font-family: source_code_pro_light;
  src        : url("../../assets/fonts/SourceCodePro/SourceCodePro-Light.ttf") format("opentype");
}

$font-primary      : 'Nunito';
$font-primary_b    : 'Nunito-bold';
$font-primary_eb   : 'Nunito-extra-bold';
$font-secondary    : 'creato-display_regular';
$font-secondary_b  : 'creato-display_bold';
$font-secondary_eb : 'creato-display_extra_bold';

.font-primary {
  font-family: $font-primary,
    sans-serif;

  &--b {
    @extend .font-primary;
    font-family: $font-primary_b,
      sans-serif;
  }

  &--eb {
    @extend .font-primary;
    font-family: $font-primary_eb,
      sans-serif;
  }
}

.font-secondary {
  font-family: $font-secondary,
    sans-serif;

  &--b {
    @extend .font-secondary;
    font-family: $font-secondary_b,
      sans-serif;
  }



  &--eb {
    @extend .font-secondary;
    font-family: $font-secondary_eb,
      sans-serif;
  }


}


* {
  font-family: $font-primary, sans-serif;
}
