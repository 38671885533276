.btn {
  border       : 0;
  font-size    : 15px;
  cursor       : pointer;
  padding      : 3px 13px;
  border-radius: 5px;

  &--action {
    color: white;

    font-weight: bold;
    border     : 0;
  }

  &--back {
    @extend .btn;
    height: auto;
    width : auto;

    padding         : 5px 5px;
    background-color: #fff;
    box-shadow      : 0px 0px 30px #00000029;
    border-radius   : 10px;
    transform       : scale(.8);

    &:hover {}

    .icon {}

  }

  &--cancel {


    &:hover {
      background-color: rgba(186, 0, 186, 0.22);
    }
  }

  &:hover {}

  &--accent {
    @extend .btn;


    &:hover {}
  }

  &--neutral {
    @extend .btn;
    background-color: #a5a5a5;
    color           : white;

    .label {
      margin-left: 5px;
    }
  }

  &--action {
    @extend .btn;
    text-transform  : capitalize !important;
    border          : 1px solid #e4e4e4;
    border-radius   : 8px;
    padding         : 5px 25px;
    color           : #707070;
    font            : normal normal normal 18px/24px Nunito;
    background-color: transparent;
    margin          : 0 4.5px;

    &:hover {

      color: #fff;

    }
  }

  &--outline {
    border       : 1px solid #A2A2A2;
    border-radius: 5px;
  }

  transition: all .5s ease;
}
