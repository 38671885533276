@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import './styles/theme/main.scss';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.modal-lg,
.modal-xl {
  max-width: 620px !important;
}
